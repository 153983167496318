@media all and (min-width: 480px) {
  .portfolio {
    background: linear-gradient(90deg, #a3bcb6 30%, #164a41 30%);
  }

  .spaceBeforePortfolio {
    height: 80px;
  }

  .projectTitle {
    height: 50px;
    padding-left: 50px;
    color: #f8f8ff;
    text-align: center;
  }

  .mainPortfolio {
    display: grid;
    margin-left: 50px;
    text-align: center;
  }

  .projectGitHub {
    color: #a3bcb6;
  }

  .projects {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-rows: 2fr 2fr;
  }

  .projectText {
    margin-left: 10px;
    font-family: "Lato";
  }

  .card-img-top {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    transition: transform 1s;
  }

  .img__wrap {
    position: relative;
  }

  .img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #164a41;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: left;
    justify-content: top;
    transition: opacity 0.4s, visibility 0.4s;
    padding-left: 10%;
    padding-top: 5%;
    padding-right: 8%;
  }

  .img__wrap:hover .img__description_layer {
    visibility: visible;
    opacity: 1;
  }

  .img__description {
    transition: 0.4s;
    transform: translateY(1em);
    font-family: "Lato";
  }

  .img__wrap:hover .img__description {
    transform: translateY(0);
  }

  .card {
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 0px;
    margin: 10%;
    /* position: absolute; */
  }

  .square {
    opacity: 0;
    transform-origin: 0 0;
    /* cursor: pointer; */
  }

  .githubPortfolio {
    font-size: 28px;
  }

  .playPortfolio {
    font-size: 28px;
  }

  .tooltip {
    top: -5px;
    left: 105%;
    background-color: red;
  }
}
