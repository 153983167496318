@media all and (min-width: 480px) {
  .Home {
    background: linear-gradient(90deg, #a3bcb6 30%, #164a41 30%);
  }

  .home-lander {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .mainPhoto {
    width: 80%;
    border-bottom: 5px solid;
    border-top: 5px solid;
    border-right: 5px solid;
    border-color: white;
  }
  .greeting {
    text-align: center;
    margin-top: 30%;
    margin-bottom: 0px !important;
    color: #f8f8ff;
  }

  /* PROJECT CARDS */

  .skillsImage {
    font-size: 40px;
    color: #a3bcb6;
    margin-top: 15px;
    margin-bottom: 35px;
  }

  .skillsText {
    font-size: 16px;
    font-family: "Lato";
    font-weight: 500;
  }

  .contentBeforeProjectGrid {
    height: 70px;
  }

  .projectPreview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    background: linear-gradient(90deg, #a3bcb6 30%, #164a41 30%);
  }

  .homeProject {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #f8f8ff;
    text-align: center;
  }

  .greetingSentence {
    font-family: "Lato";
  }

  /* SEE PROJECTS / RESUME SECTION */

  .contentBeforeResumeGrid {
    height: 100px;
  }

  .projectResumeLinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .seeProjects {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }
  .resume {
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
  }

  .homeProjectLink {
    text-align: center;
    margin-top: 20px;
  }

  .resumeTitle {
    margin-left: 15px;
  }
  .toGitHubLink {
    margin-left: 15px;
    margin-top: 17px;
  }

  .resumeLink {
    text-align: center;
    margin-bottom: 0px;
  }

  .education {
    color: white;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  .school {
    margin: 15px;
    margin-bottom: 35px;
  }

  .educationTitle {
    margin-bottom: 25px;
  }

  .educationDescription {
    font-family: "Lato";
  }

  .homeFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    color: white;
    background: linear-gradient(270deg, #164a41 70%, #a3bcb6 70%);
    height: 110px;
  }

  .contentBeforeFooterGrid {
    height: 80px;
  }

  .linked {
    font-size: 20px;
  }
  .github {
    font-size: 20px;
  }

  .contactInfoAll {
    margin-top: 70px;
    padding-left: 20px;
  }

  .copyrightName {
    text-align: right;
    margin-top: 70px;
    padding-right: 20px;
  }
}
