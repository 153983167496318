body {
  font-size: 16px;
  font-family: "Abril Fatface", cursive;
  -webkit-font-smoothing: antialiased;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}

.nameTop {
  color: #f8f8ff;
}

a {
  color: #266150;
}

a:hover {
  text-decoration: none;
  color: #4d774e;
}

.Icon {
  background-size: 10rem 10rem;
}

.navbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  background: linear-gradient(270deg, #164a41 70%, #a3bcb6 70%);
  margin-bottom: 0px !important;
  position: sticky;
  top: 0;
  z-index: 99998;
  transition: 0.3s all;
  padding: 18px;
  border-bottom: 2px solid #f8f8ff;
}

.rightNavbar {
  color: white !important;
}

.homeLink {
  padding-right: 40px !important;
  padding-top: 35px !important;
  color: #f8f8ff !important;
}

.homeLink:hover {
  text-decoration: underline;
  text-decoration-color: #e4c287;
  text-decoration-thickness: 2px;
  text-decoration-style: wavy;
  text-underline-offset: 0.4em;
}
.aboutLink {
  padding-right: 40px !important;
  padding-top: 35px !important;
  color: #f8f8ff !important;
}
.aboutLink:hover {
  text-decoration: underline;
  text-decoration-color: #e4c287;
  text-decoration-thickness: 2px;
  text-decoration-style: wavy;
  text-underline-offset: 0.4em;
}
.portfolioLink {
  padding-right: 40px !important;
  padding-top: 35px !important;
  color: #f8f8ff !important;
}
.portfolioLink:hover {
  text-decoration: underline;
  text-decoration-color: #e4c287;
  text-decoration-thickness: 2px;
  text-decoration-style: wavy;
}
.connectLink {
  padding-right: 12px !important;
  color: white !important;
  padding-top: 25px !important;
  transform: rotate(20deg);
  text-align: center;
}

.circle {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  background-color: #a3bcb6;
  padding-right: -20px !important;
  padding-top: -20px;
}

.circle:hover {
  background-color: #e4c287;
}
